import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'src/services/redux/app/store';
import { notificationsRead } from 'src/services/redux/slices/notifications/notifications-slice';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks } from '../../../partials';
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../core';
import { useMarketPlaceWorkPlaceInquiryList } from '../../service';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-30px',
  toolbarButtonIconSizeClass = 'svg-icon-1';

const Topbar: FC = () => {
  const dispatch = useAppDispatch();
  const [notification, setNotification] = useState<boolean>(false);
  const [userMenu, setUserMenu] = useState<boolean>(false);
  const { unreads } = useAppSelector((state) => state.unreads);
  const { payment_details } = useAppSelector((state) => state.paymentsDetail);
  const { user } = useAppSelector((state) => state.auth);
  const { config, setLayout } = useLayout();
  const [configLayout, setConfigLayout] = useState<ILayout>(getLayout());
  const updateData = (fieldsToUpdate: Partial<ILayout>) => {
    const updatedData = { ...configLayout, ...fieldsToUpdate };
    setConfigLayout(updatedData);
    try {
      LayoutSetup.setConfig(updatedData);
    } catch (error) {
      setConfigLayout(getLayout());
    }
    setLayout(updatedData);
  };
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        onClick={() => {
          updateData({
            aside: {
              ...config.aside,
              theme: config.aside.theme === 'dark' ? 'light' : 'dark',
            },
          });
        }}
      >
        {/* begin::Menu- wrapper */}
        <div
          className={clsx('btn btn-icon btn-active-light-primary position-relative', toolbarButtonHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {config.aside.theme === 'light' ? (
            <KTSVG path={`/media/icons/duotone/Weather/Sun.svg`} className={toolbarButtonIconSizeClass} />
          ) : (
            <KTSVG path={`/media/icons/duotone/Weather/Moon.svg`} className={toolbarButtonIconSizeClass} />
          )}
        </div>
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div className={clsx('d-flex align-items-center  ', toolbarButtonMarginClass)} id="kt_header_user_menu_toggle">
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol position-relative ', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="YELİAPP" />
        </div>
        <HeaderUserMenu show={userMenu} />

        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
          <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_header_menu_mobile_toggle">
            <KTSVG path="/media/icons/duotone/Text/Toggle-Right.svg" className="svg-icon-1" />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
