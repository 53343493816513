// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

var firebaseConfig = {
  apiKey: 'AIzaSyCfTr3EPJdJa3eX46jU2-qhrzbWf3LvoYI',
  authDomain: 'eduthon-2e3d7.firebaseapp.com',
  projectId: 'eduthon-2e3d7',
  storageBucket: 'eduthon-2e3d7.appspot.com',
  messagingSenderId: '1000062116508',
  appId: '1:1000062116508:web:bc4a07e01366cb98e70359',
  measurementId: 'G-Z4NV9WCFPF',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: `BPYWn1NK7bfdBD9DbRlPLG23tcwYdSdqb-j_a8rd4knP6MCA38xnp5l68NGusGAAyiuogE7SIxhJF7zb7w0XOAs` })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
