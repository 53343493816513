import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/tr';
import { useAppSelector } from 'src/services/redux/app/store';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { Item1 } from '../../content/activity/Item1';
import { Item2 } from '../../content/activity/Item2';
import { Item3 } from '../../content/activity/Item3';
import { Item4 } from '../../content/activity/Item4';
import { Item5 } from '../../content/activity/Item5';
import { Item6 } from '../../content/activity/Item6';
import { Item7 } from '../../content/activity/Item7';
import { Item8 } from '../../content/activity/Item8';

const ActivityDrawer: FC = () => {
  return (
    <div
      id="kt_activities"
      className="bg-white"
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div className="card shadow-none rounded-0 w-100">
        <div className="card-header" id="kt_activities_header">
          <h3 className="card-title fw-bolder text-dark">Log Kayıtları</h3>

          <div className="card-toolbar">
            <button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
              <KTSVG path="/media/icons/duotone/Navigation/Close.svg" className="svg-icon-1" />
            </button>
          </div>
        </div>
        <div className="card-body position-relative" id="kt_activities_body">
          <div
            id="kt_activities_scroll"
            className="position-relative scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_activities_body"
            data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
            data-kt-scroll-offset="5px"
          >
            <div className="timeline">
              {/* {logs?.results?.map((log, index) => (
                <div className="timeline-item" key={index}>
                  <div className="timeline-line w-40px"></div>
                  <div className="timeline-icon symbol symbol-circle symbol-40px">
                    <div className="symbol-label bg-light">
                      {log?.log_type?.log_type_name === 'Site İşlemleri' && <KTSVG path="/media/icons/duotone/Communication/Thumbtack.svg" className="svg-icon-2 svg-icon-gray-500" />}
                      {log?.log_type?.log_type_name === 'Başvuru İşlemleri' && <KTSVG path="/media/icons/duotone/Communication/Write.svg" className="svg-icon-2 svg-icon-gray-500" />}
                      {log?.log_type?.log_type_name === 'Finans' && <KTSVG path="/media/icons/duotone/Communication/Urgent-mail.svg" className="svg-icon-2 svg-icon-gray-500" />}
                      {log?.log_type?.log_type_name === 'Raporlama' && <KTSVG path="/media/icons/duotone/Home/Library.svg" className="svg-icon-2 svg-icon-gray-500" />}
                      {log?.log_type?.log_type_name === 'Kullanıcı' && <KTSVG path="/media/icons/duotone/General/User.svg" className="svg-icon-2 svg-icon-gray-500" />}
                    </div>
                  </div>

                  <div className="timeline-content mb-10 mt-n2">
                    <div className="overflow-auto pe-3">
                      <div className=" fw-bold mb-2 fs-5">{log?.content}</div>

                      <div className="d-flex align-items-center mt-1 fs-6">
                        <div className="text-muted me-2 fs-7">{moment(log?.created_at).fromNow()}</div>

                        <div className="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title={log?.user?.name}>
                          <img src={log?.user?.profile_image ? log?.user?.profile_image : toAbsoluteUrl('/media/avatars/blank.png')} alt="img" />
                        </div>
                      </div>
                    </div>
                    {log?.documents && (
                      <div className="overflow-auto pb-5 pt-5">
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                          {log?.documents?.map((doc: any, index: number) => (
                            <div className="overlay me-10" key={index}>
                              <div className="overlay-wrapper">
                                <img alt="img" className="rounded w-200px h-200px" src={doc} />
                              </div>

                              <div className="overlay-layer bg-dark bg-opacity-10 rounded"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
        <div className="card-footer py-5 text-center" id="kt_activities_footer">
          <Link to="/crafted/pages/profile" className="btn btn-bg-white text-primary">
            View All Activities
            <KTSVG path="/media/icons/duotone/Navigation/Right-2.svg" className="svg-icon-3 svg-icon-primary" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export { ActivityDrawer };
