import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../../app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export interface IUnRead {
  notifications_unread_count: number;
  logs_unread_count: number;
}

const initialState: IAuthState = {
  unreads: undefined,
};

export interface IAuthState {
  unreads?: IUnRead | null;
}
export const unreadsList = createAsyncThunk('unreads/list', async () => {
  const company_id = store.getState()?.auth?.user?.company?.id;
  var params = { company_id };
  const res = await axios.get<any>(`${REACT_APP_API_URL}/unreads/list`, { params });
  return res.data;
});

export const unreadsSlice = createSlice({
  name: 'EDunreads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(unreadsList.fulfilled, (state, action) => {
      state.unreads = action.payload;
    });
  },
});

// export const { _login, _logOut, _setStamp } = notificationsSlice.actions;
export default unreadsSlice.reducer;
