/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { Error404 } from './components/Error404';
import { Error500 } from './components/Error500';

const ErrorsPage: React.FC = () => {
  const history = useHistory();
  const redirectToDashboard = () => {
    history.push('/');
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')` }}
      >
        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
          <a href="/dashboard" className="mb-10 pt-lg-20">
            <h1 className="fw-bolder fs-4x text-gray-700 mb-10">YELİAPP</h1>
          </a>
          <div className="pt-lg-10">
            <Switch>
              <Route path="/error/404" exact={true}>
                <Error404 />
              </Route>
              <Route path="/error/500" exact={true}>
                <Error500 />
              </Route>
              <Redirect from="/error" exact={true} to="/error/404" />
              <Redirect to="/error/404" />
            </Switch>

            <div className="text-center">
              <a onClick={redirectToDashboard} className="btn btn-lg btn-primary fw-bolder">
                Anasayfa
              </a>
            </div>
          </div>
        </div>

        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://apithon.com.tr" className="text-muted text-hover-primary px-2">
              Anasayfa
            </a>
            <a href="mailto:bilgi@yeliapp.com" className="text-muted text-hover-primary px-2">
              İletişim
            </a>
            <a href="https://apithon.com.tr" className="text-muted text-hover-primary px-2">
              Hakkımızda
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ErrorsPage };
