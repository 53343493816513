/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../helpers';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotone/Home/Home2.svg"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title="Anasayfa"
        fontIcon="bi-app-indicator"
      />

      {/* <AsideMenuItemWithSub to="/applications" title="İşletme İşlemleri" fontIcon="bi-archive" icon="/media/icons/duotone/Code/Compiling.svg">
        <AsideMenuItem to="/applications/list" title="İşletmeler" hasBullet={true} /> */}
      {/* <AsideMenuItem to="/applications/search" title="İşletme Sorgula" hasBullet={true} /> */}
      {/* <AsideMenuItem to="/applications/branch" title="Şube İşlemleri" hasBullet={true} /> */}
      {/* </AsideMenuItemWithSub> */}

      <AsideMenuItem
        to="/applications/list"
        icon="/media/icons/duotone/Map/Location-arrow.svg"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title="İşletmeler"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/badges"
        icon="/media/icons/duotone/Design/PenAndRuller.svg"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title="Rozetler"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItemWithSub to="/categories" title="Kategoriler" fontIcon="bi-app-indicator" icon="/media/icons/duotone/Code/Option.svg">
        <AsideMenuItem to="/categories" title="İşletme Kategorileri" hasBullet={true} />
        <AsideMenuItem to="/category/corporate" title="Resmi Kurumlar" hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to="/activities" title="Etkinlikler" fontIcon="bi-archive" icon="/media/icons/duotone/Code/Compiling.svg">
        <AsideMenuItem to="/activity/categories" title="Etkinlik Kategorileri" hasBullet={true} />
        <AsideMenuItem to="/activities" title="Etkinlik Yönetimi" hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to="/app-users"
        icon="/media/icons/duotone/General/User.svg"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title="Üyeler"
        fontIcon="bi-app-indicator"
      />

      {/* <AsideMenuItem
        to="/apps/chat"
        icon="/media/icons/duotone/Communication/Chat.svg"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title="Mesajlar"
        fontIcon="bi-app-indicator"
      /> */}

      <AsideMenuItemWithSub to="/finance" title="Finans" icon="/media/icons/duotone/Layout/Layout-4-blocks.svg" fontIcon="bi-layers">
        <AsideMenuItemWithSub to="/finance/income" title="Gelir/Gider İşlemleri" icon="/media/icons/duotone/Shopping/Money.svg" fontIcon="bi-person">
          <AsideMenuItem to="/finance/income/list" title="Gelir/Gider Bilgileri" hasBullet={true}></AsideMenuItem>
          <AsideMenuItem to="/finance/income/types" title="Gelir/Gider Tipleri" hasBullet={true} />
        </AsideMenuItemWithSub>
        {/* <AsideMenuItem to="/finance/operations/lists" title="Ödemeler" hasBullet={true} />
        <AsideMenuItem to="/finance/operations/create" title="Ödeme Oluştur" hasBullet={true} /> */}
        <AsideMenuItem to="/finance/operations/e-arsiv" title="G.İ.B e-arşiv" hasBullet={true} />
        {/* <AsideMenuItem to="/finance/operations/debt-inquiry" title="Borç Sorgula" hasBullet={true} />
        <AsideMenuItem to="/finance/operations/end-of-day-report" title="Gün Sonu Raporu" hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub to="/reports" title="Raporlama" icon="/media/icons/duotone/Layout/Layout-horizontal.svg" fontIcon="bi-layers">
        <AsideMenuItem to="/reports/user" title="Kullanıcı Raporu" hasBullet={true} />
        <AsideMenuItem to="/reports/registration" title="Başvuru Raporu" hasBullet={true} />
        <AsideMenuItem to="/reports/payment_statements" title="Finans Raporu" hasBullet={true} />
      </AsideMenuItemWithSub> */}
      <AsideMenuItem to="/tickets" icon="/media/icons/duotone/Communication/Mail-opened.svg" title="Destek Talepleri" fontIcon="bi-app-indicator" />

      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>

      <div className="menu-item">
        <Link className="menu-link" to={'/release-notes'}>
          <span className="menu-icon">
            <KTSVG path="/media/icons/duotone/Files/File.svg" className="svg-icon-2" />
          </span>
          <span className="menu-title">Sürüm Notları {process.env.REACT_APP_VERSION}</span>
        </Link>
      </div>
    </>
  );
}
