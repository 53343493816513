import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/services/redux/app/store';
import { companyList } from 'src/services/redux/slices/auth/auth-slice';
import { notificationsList } from 'src/services/redux/slices/notifications/notifications-slice';
import { payment_detailsList } from 'src/services/redux/slices/payments-detail/payments-detail-slice';
import { unreadsList } from 'src/services/redux/slices/unread/unreads-slice';
import { DefaultLayoutConfig } from './DefaultLayoutConfig';
import { ILayout, ILayoutCSSVariables, ILayoutCSSClasses, ILayoutHTMLAttributes } from './LayoutModels';
import { getEmptyCssClasses, getEmptyCSSVariables, getEmptyHTMLAttributes, LayoutSetup } from './LayoutSetup';

export interface LayoutContextModel {
  config: ILayout;
  classes: ILayoutCSSClasses;
  attributes: ILayoutHTMLAttributes;
  cssVariables: ILayoutCSSVariables;
  setLayout: (config: LayoutSetup) => void;
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
});

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex');
  }
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none');
  }
};

const LayoutProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [config, setConfig] = useState(LayoutSetup.config);
  const [classes, setClasses] = useState(LayoutSetup.classes);
  const [attributes, setAttributes] = useState(LayoutSetup.attributes);
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables);
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen();
    const bodyClasses = Array.from(document.body.classList);
    bodyClasses.forEach((cl) => document.body.classList.remove(cl));
    LayoutSetup.updatePartialConfig(_themeConfig);
    setConfig(Object.assign({}, LayoutSetup.config));
    setClasses(LayoutSetup.classes);
    setAttributes(LayoutSetup.attributes);
    setCSSVariables(LayoutSetup.cssVariables);
    setTimeout(() => {
      disableSplashScreen();
    }, 500);
  };
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
  };

  const initSplash = async () => {
    if (user) {
      // dispatch(companyList());
      // dispatch(notificationsList({ page: 1, limit: 10, sortBy: 'desc' }));
      // dispatch(logsList({ page: 1, limit: 10 }));
      // dispatch(unreadsList());
      // dispatch(payment_detailsList());
    }
    disableSplashScreen();
  };

  useEffect(() => {
    initSplash();
  }, []);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
  return useContext(LayoutContext);
}
