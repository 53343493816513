import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useAppDispatch } from 'src/services/redux/app/store';
import { _login } from 'src/services/redux/slices/auth/auth-slice';
import * as Yup from 'yup';
import { requestPassword, resetPassword, twoFactorLogin } from '../redux/AuthCRUD';

const initialValues = {
  id: '',
  code: '',
};

const forgotPasswordSchema = Yup.object().shape({
  code: Yup.string().min(6, 'Lütfen geçerli bir doğrulama kodu girin').max(6, 'Lütfen geçerli bir doğrulama kodu girin').required('Doğrulama kodunu belirtin.'),
});

export function TwoFactor() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { state }: any = location;

  useEffect(() => {
    if (!state?.email || !state?.id) {
      history.push({ pathname: `/` });
    }
  }, [state?.email, state?.id]);

  const formik = useFormik({
    initialValues: values,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      twoFactorLogin({ id: state?.id, code: values.code })
        .then(({ data }: any) => {
          dispatch(_login(data));
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          setSubmitting(false);
          toast.error(error?.response?.data?.message);
        });
    },
  });

  return (
    <>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate id="kt_login_password_reset_form" onSubmit={formik.handleSubmit}>
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="fw-bolder fs-2x text-gray-800 mb-10">İki Faktörlü Doğrulama</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-6">
            {'Lütfen email adresinize gönderilen doğrulama kodunu girin. Eğer doğrulama kodu size ulaşmadıysa kurum ile iletişime geçebilirsiniz.'}
          </div>
          {/* end::Link */}
        </div>

        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <input
            type="text"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps('code')}
            style={{ letterSpacing: 20 }}
            className={clsx(
              'form-control form-control-lg form-control-solid text-center fs-1',
              { 'is-invalid': formik.touched.code && formik.errors.code },
              {
                'is-valid': formik.touched.code && !formik.errors.code,
              },
              {
                'border-danger': formik.touched.code && formik.errors.code,
              },
            )}
          />
          {formik.touched.code && formik.errors.code && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span className="text-danger" role="alert">
                  {formik.errors.code}
                </span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button type="submit" id="kt_password_reset_submit" className="btn btn-lg btn-light-primary fw-bolder me-4" disabled={formik.isSubmitting || !formik.isValid}>
            <span className="indicator-label">{'Devam Et'}</span>
            {loading && (
              <span className="indicator-progress">
                Lütfen bekleyin...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button type="button" id="kt_login_password_reset_form_cancel_button" className="btn btn-lg btn-secondary fw-bolder">
              İptal
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
