/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/_metronic/helpers';
import { useAppSelector } from 'src/services/redux/app/store';
import { Languages } from './Languages';
import './styles/styles.css';

type Props = {
  show: boolean;
};

const HeaderUserMenu: FC<Props> = ({ show }) => {
  const user = useAppSelector(({ auth }) => auth.user, shallowEqual);
  const { payment_details } = useAppSelector((state) => state.paymentsDetail);

  return (
    <div className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px `} data-kt-menu="true">
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3 ">
          <div className="symbol symbol-50px me-5">
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="YELİAPP" />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-6  flex-wrap">
              {user?.name} {user?.surname}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{user?.authorization?.authorization_name}</span>
            </div>
            <span className="text-muted fs-7">{user?.company?.company_name}</span>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5 my-1 ">
        <Link to="/account/settings" className="menu-link px-5 d-flex align-items-center ">
          Hesap Ayarları
        </Link>
      </div>

      <div className="menu-item px-5">
        <Link to="/logout" className="menu-link px-5">
          Çıkış
        </Link>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
