/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  ListsWidget1,
  ListsWidget7,
  ListsWidget8,
  EngageWidget1,
  EngageWidget2,
  EngageWidget5,
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget3,
  ChartsWidget4,
  ChartsWidget5,
  ChartsWidget6,
  ChartsWidget7,
  ChartsWidget8,
  FeedsWidget2,
  FeedsWidget3,
  FeedsWidget5,
  FeedsWidget6,
  MixedWidget1,
  MixedWidget3,
  MixedWidget9,
  StatisticsWidget6,
  StatisticsWidget1,
  StatsWidget1,
  StatsWidget2,
  StatsWidget3,
  StatsWidget4,
  StatsWidget5,
  StatsWidget6,
  EngageWidget3,
  StatsWidget7,
  StatsWidget8,
  StatsWidget9,
  MixedWidget4,
  MixedWidget5,
  MixedWidget6,
  TablesWidget1,
  TablesWidget3,
  TablesWidget2,
  TablesWidget4,
  TablesWidget6,
  TablesWidget7,
  TablesWidget8,
  TablesWidget9,
  TablesWidget11,
  TablesWidget12,
  TablesWidget13,
  FeedsWidget4,
  StatisticsWidget2,
} from '../../../_metronic/partials/widgets';
import { Activity } from './components/activity';
import { LastMonthly } from './components/last-monthly';
import { Members } from './components/members';
import { PayMonthly } from './components/pay-monthly';
import { Todos } from './components/todos';
import { UsersIstatistics } from './components/user-istatistics';
import { useDashboardList } from './service';

type Props = {
  dashboardList: any;
};

const DashboardPage: FC<Props> = ({ dashboardList }) => (
  <>
    {/* begin::Row */}
    {/* <div className="row gy-5 g-xl-8">
      <div className="col-xxl-4">
        <UsersIstatistics className="card-xl-stretch mb-xl-8" chartColor="danger" chartHeight="200px" strokeColor="#cb1e46" dashboardList={dashboardList} setFilters={setFilters} />
      </div>
      <div className="col-xxl-4">
        <LastMonthly className="card-xxl-stretch mb-5 mb-xl-8" chartColor="primary" chartHeight="150px" dashboardList={dashboardList} />
      </div>
      <div className="col-xxl-4">
        <PayMonthly className="card-xxl-stretch mb-5 mb-xl-8" chartColor="primary" chartHeight="175px" dashboardList={dashboardList} />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 gx-xl-8">
      {/* <div className="col-xxl-4">
        <Todos className="card-xxl-stretch mb-xl-3" dashboardList={dashboardList} />
      </div> */}
      {/* <div className="col-xl-12">
        <Members className="card-xxl-stretch mb-5 mb-xl-8" dashboardList={dashboardList} />
      </div> */}
    </div>
    {/* end::Row */}

    {/* <div className="row gy-5 g-xl-8">
      <div className="col-xl-6">
        <EngageWidget5 className="card-xl-stretch mb-xl-8" />
      </div>
      <div className="col-xl-6">
        <MixedWidget1 color="danger" className="card-xl-stretch mb-xl-8" dashboardList={dashboardList} />
      </div>
    </div> */}

    {/* <div className="row g-5 gx-xxl-8">
      <div className="col-xxl-6">
        <StatsWidget6 className="card-xl-stretch mb-5 mb-xl-8" />
      </div>
      <div className="col-xxl-6">
        <MixedWidget6 chartHeight="200px" chartColor="success" className="card-xl-stretch mb-xl-8" />
      </div>
    </div> */}
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const onErrorFetch = (e: any) => e?.response?.data?.message && toast.error(e?.response?.data?.message);
  const [filters, setFilters] = useState<any>({});
  const { refetch, data: dashboardList, isLoading, isFetching } = useDashboardList({ ...filters }, onErrorFetch);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage dashboardList={dashboardList} />
    </>
  );
};

export { DashboardWrapper };
