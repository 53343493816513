import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../../app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export interface INotifications {
  results: Array<{
    id: number;
    notifications_id: number;
    title: string;
    content: string;
    created_at: string;
    updated_at: string;
    user_id: null | number;
    is_read: boolean | any;
  }>;
  page: number;
  limit: number;
  totalResults: number;
  totalPages: number;
}

const initialState: IAuthState = {
  notifications: undefined,
};

export interface IAuthState {
  notifications?: INotifications | null;
}
export const notificationsList = createAsyncThunk('notifications/list', async (params: any) => {
  const notifications_id = store.getState()?.auth?.user?.company?.id;
  params = { ...params, notifications_id };
  const res = await axios.get<any>(`${REACT_APP_API_URL}/notifications/list`, { params });
  return res.data;
});

export const notificationsRead = createAsyncThunk('notifications/read', async () => {
  const notifications_id = store.getState()?.auth?.user?.company?.id;
  const res = await axios.post<any>(`${REACT_APP_API_URL}/notifications/read`, { notifications_id });
  return res.data;
});

export const notificationsSlice = createSlice({
  name: 'EDnotifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notificationsList.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
    //
    builder.addCase(notificationsRead.fulfilled, (state, action) => {});
  },
});

// export const { _login, _logOut, _setStamp } = notificationsSlice.actions;
export default notificationsSlice.reducer;
