/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { _login } from 'src/services/redux/slices/auth/auth-slice';
import isURL from 'validator/lib/isURL';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { register } from '../redux/AuthCRUD';
import * as auth from '../redux/AuthRedux';

const initialValues = {
  company_name: '',
  tax_number: '',
  contact_name: '',
  contact_surname: '',
  contact_phone: '',
  email: '',
  password: '',
  changepassword: '',
  company_logo: '',
  web_site: '',
  acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
  company_name: Yup.string().min(3, 'Minimum 3 karakter girin').max(50, 'Maksimum 50 karakter girin').required('Kurum adı zorunludur.'),
  tax_number: Yup.string().min(10, 'Minimum 10 karakter girin').max(10, 'Maksimum 10 karakter girin').required('Vergi numarası zorunludur.'),
  contact_name: Yup.string().min(3, 'Minimum 3 karakter girin').max(50, 'Maksimum 50 karakter girin').required('İletişim için adınızı girin.'),
  contact_surname: Yup.string().min(3, 'Minimum 3 karakter girin').max(50, 'Maksimum 50 karakter girin').required('İletişim için soyadınızı girin.'),
  contact_phone: Yup.string().required('İletişim için telefon numaranızı girin.'),
  password: Yup.string()
    .required('Password is required')
    .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/, '8 Karakter, Bir Büyük Harf, Bir Küçük Harf, Bir Sayı ve Bir Özel Durum Karakteri İçermelidir'),
  web_site: Yup.string()
    .lowercase()
    .test('isSlug', 'Lütfen geçerli bir web sitesi belirtin.', (val: any) => val && isURL(val)),
  email: Yup.string().email('Geçersiz email adresi girdiniz').min(3, 'Minimum 3 karakter').max(50, 'Maksimum 50 karakter').required('Email adresi zorunludur'),
  changepassword: Yup.string()
    .required('Şifre onayı gerekli')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Şifreleriniz uyuşmuyor.'),
    }),
  acceptTerms: Yup.bool().required('Şartları ve koşulları kabul etmelisiniz'),
});

export function Registration() {
  const refPhone = useRef<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      var body = { ...values };
      body.contact_phone = body?.contact_phone?.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '');
      register(body)
        .then(({ data }) => {
          setLoading(false);
          toast.success('Kayıt işleminiz başarıyla gerçekleştirildi.');
          dispatch(_login(data));
          // dispatch(auth.actions.login(accessToken));
        })
        .catch((error: any) => {
          setLoading(false);
          setSubmitting(false);
          toast.error(error?.response?.data?.message);
        });
    },
  });

  useEffect(() => {
    const inputMask = (e: any) => {
      var x = e.target.value?.replace(/\D/g, '')?.match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      if (x) {
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + ' ' + x[3] + ' ' + x[4];
      }
      formik.setFieldValue('contact_phone', e.target.value);
    };
    if (refPhone && refPhone.current) {
      // Passing the same reference
      refPhone.current.addEventListener('input', inputMask);
    }
    return () => {
      (refPhone as any)?.current?.removeEventListener('input', inputMask);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate id="kt_login_signup_form" onSubmit={formik.handleSubmit}>
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="fw-bolder fs-2x text-gray-800 mb-10">Hesabınızı Oluşturun</h1>

        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-400 fw-bold fs-4">
          Zaten hesabınız var mı?
          <Link to="/auth/forgot-password" className="link-primary fw-bolder mx-2">
            Parolanızı sıfırlayın
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      <div className="d-flex align-items-center mb-10">
        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
        <span className="fw-bold text-gray-400 fs-7 mx-2">VEYA</span>
        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className="row fv-row">
        <div className="col-xl-6">
          <label className="form-label fw-bold text-gray-600 fs-6">Adınız</label>
          <input
            type="text"
            autoComplete="off"
            {...formik.getFieldProps('contact_name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.contact_name && formik.errors.contact_name,
              },
              {
                'is-valid': formik.touched.contact_name && !formik.errors.contact_name,
              },
            )}
          />
          {formik.touched.contact_name && formik.errors.contact_name && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span className="text-danger" role="alert">
                  {formik.errors.contact_name}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="col-xl-6">
          {/* begin::Form group contact_surname */}
          <div className="fv-row mb-5">
            <label className="form-label fw-bold text-gray-600 fs-6">Soyadınız</label>
            <input
              type="text"
              autoComplete="off"
              {...formik.getFieldProps('contact_surname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.contact_surname && formik.errors.contact_surname,
                },
                {
                  'is-valid': formik.touched.contact_surname && !formik.errors.contact_surname,
                },
              )}
            />
            {formik.touched.contact_surname && formik.errors.contact_surname && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="text-danger" role="alert">
                    {formik.errors.contact_surname}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}
      {/* begin::Form group contact_phone */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-gray-600 fs-6">Telefon Numarası</label>
        <input
          ref={refPhone}
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('contact_phone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.contact_phone && formik.errors.contact_phone },
            {
              'is-valid': formik.touched.contact_phone && !formik.errors.contact_phone,
            },
          )}
        />
        {formik.touched.contact_phone && formik.errors.contact_phone && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="text-danger" role="alert">
                {formik.errors.contact_phone}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group company_name */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-gray-600 fs-6">Kurum Adı</label>
        <input
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('company_name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.company_name && formik.errors.company_name },
            {
              'is-valid': formik.touched.company_name && !formik.errors.company_name,
            },
          )}
        />
        {formik.touched.company_name && formik.errors.company_name && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="text-danger" role="alert">
                {formik.errors.company_name}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-gray-600 fs-6">Kurum Web Sitesi</label>
        <input
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('web_site')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.web_site && formik.errors.web_site },
            {
              'is-valid': formik.touched.web_site && !formik.errors.web_site,
            },
          )}
        />
        {formik.touched.web_site && formik.errors.web_site && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="text-danger" role="alert">
                {formik.errors.web_site}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group tax_number */}

      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-gray-600 fs-6">Vergi No.</label>
        <input
          autoComplete="off"
          {...formik.getFieldProps('tax_number')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.tax_number && formik.errors.tax_number },
            {
              'is-valid': formik.touched.tax_number && !formik.errors.tax_number,
            },
          )}
        />
        {formik.touched.tax_number && formik.errors.tax_number && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="text-danger" role="alert">
                {formik.errors.tax_number}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group Email */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-gray-600 fs-6">Email</label>
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="text-danger" role="alert">
                {formik.errors.email}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bold text-gray-600 fs-6">Şifre</label>
          <div className="position-relative mb-3">
            <input
              type="password"
              autoComplete="off"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                },
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span className="text-danger" role="alert">
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bold text-gray-600 fs-6">Şifre Tekrar</label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            },
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="text-danger" role="alert">
                {formik.errors.changepassword}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="form-check form-check-custom form-check-solid">
          <input className="form-check-input" type="checkbox" id="kt_login_toc_agree" {...formik.getFieldProps('acceptTerms')} />
          <label className="form-check-label fw-bold text-gray-700 fs-6" htmlFor="kt_login_toc_agree">
            <a href="https://www.iyzico.com/pazaryeri-satici-anlasma" target={'_blank'} rel="noreferrer" className="ms-1 link-primary">
              Ödeme hizmeti sözleşmesini okudum
            </a>{' '}
            kabul ediyorum.
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert" className="text-danger">
                  {formik.errors.acceptTerms}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-light-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className="indicator-label">Kayıt Ol</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Lütfen bekleyin... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button type="button" id="kt_login_signup_form_cancel_button" className="btn btn-lg btn-light-primary w-100 mb-5">
            İPTAL
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
