import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { useAppSelector } from 'src/services/redux/app/store';

const REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`;

export const useIyzipayInstallment = (params: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/iyzipay/installment';
  const fetchData = async () => {
    const res = await axios.get<any>(`${requestUrl}`, { params });
    return res?.data || {};
  };
  return useQuery(['iyzipay-installment-list'], fetchData, {
    refetchOnWindowFocus: false,
    onError,
    enabled: false,
    staleTime: Infinity,
    retry: false,
  });
};

export const useIyzipayCreate = (onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/iyzipay/create';
  return useMutation<any,any,any>(
    (formPayload: any) => {
      var payload = {
        installment: formPayload?.installment,
        month_to_be_renewed: formPayload?.month_to_be_renewed,
        price: formPayload?.price,
        paidPrice: formPayload?.price,
        paymentCard: {
          cardHolderName: formPayload?.cardHolderName,
          cardNumber: formPayload?.cardNumber,
          expireMonth: formPayload?.expireMonth,
          expireYear: formPayload?.expireYear,
          cvc: formPayload?.cvc,
          registerCard: formPayload?.registerCard ? 1 : 0,
        },
        buyer: {
          id: user?.company_id,
          name: user?.company?.contact_name,
          surname: user?.company?.contact_surname,
          gsmNumber: user?.company?.contact_phone,
          email: user?.email,
          registrationDate: moment(user?.company?.created_at).format('YYYY-MM-DD HH:mm:ss'),
          registrationAddress: formPayload?.address,
          identityNumber: '1234567890',
          city: formPayload?.city,
          country: 'Türkiye',
          zipCode: formPayload?.zipCode,
          ip: '1.1.1.1',
        },
        billingAddress: {
          contactName: formPayload?.contactName,
          city: formPayload?.city,
          country: 'Türkiye',
          address: formPayload?.address,
          zipCode: formPayload?.zipCode,
        },
        basketItems: [
          {
            id: user?.company_id,
            name: 'Hizmet Yenileme',
            category1: 'Hizmet',
            category2: 'Eğitim',
            price: formPayload?.price,
          },
        ],
      };
      return axios.post(`${requestUrl}`, { ...payload, company_id: user?.company_id });
    },
    { onError },
  );
};
export const useIyzipayThreedsInitialize = (onSuccess?: any, onError?: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const requestUrl = REACT_APP_API_URL + '/iyzipay/threedsInitialize';
  return useMutation(
    (formPayload: any) => {
      return axios.post(`${requestUrl}`, { ...formPayload, seo_id: user?.company_id });
    },
    { onError, onSuccess },
  );
};
