/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { _login } from 'src/services/redux/slices/auth/auth-slice';
import * as Yup from 'yup';
import { login } from '../redux/AuthCRUD';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Geçersiz email adresi girdiniz').min(3, 'Minimum 3 karakter').max(50, 'Maksimum 50 karakter').required('Email adresi zorunludur'),
  password: Yup.string().min(3, 'Minimum 3 karakter').max(50, 'Maksimum 50 karakter').required('Şifre zorunludur'),
});

const initialValues = {
  email: '',
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      login(values.email, values.password)
        .then(async ({ data }) => {
          setLoading(false);
          if (data?.user?.is_two_factor) {
            history.push({
              pathname: `/auth/two-factor`,
              state: { id: data?.user?.id, email: data?.user?.email },
            });
          } else {
            dispatch(_login(data));
            setTimeout(() => {
              // dispatch(payment_detailsList());
              // dispatch(notificationsList({ page: 1, limit: 10, sortBy: 'desc' }));
              // dispatch(logsList({ page: 1, limit: 10 }));
              // dispatch(unreadsList());
            }, 100);
          }
          // history.push()
        })
        .catch((error: any) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
          setSubmitting(false);
        });
    },
  });

  return (
    <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="fw-bolder fs-3x text-gray-800 mb-10">YELİAPP</h1>
        <div className="text-gray-600 fs-6 fw-bold">Yeli uygulamasına giriş yapıp kolaylıkla yönetin. </div>
      </div>

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fw-bold text-gray-600 fs-6">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bold text-gray-600 fs-6 mb-0">Şifre</label>

            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
          <Link to="/auth/forgot-password" className="link-primary fs-7 fw-bolder justify-content-end d-flex">
            Şifremi mi unuttun ?
          </Link>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            },
          )}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-light-primary w-100 mb-5" disabled={formik.isSubmitting || !formik.isValid}>
          {!loading && <span className="indicator-label">İleri</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Lütfen bekleyin...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        {/* end::Google link */}
      </div>
      <div className="d-flex align-items-center mb-10 mt-10">
        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
        <span className="fw-bold text-gray-400 fs-7 mx-2">VEYA</span>
        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
      </div>
      <Link to="/auth/registration" className="btn btn-light-success w-100 fw-bolder">
        Yeni Hesap Oluştur
      </Link>
      {/* end::Action */}
    </form>
  );
}
