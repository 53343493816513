import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FallbackView } from '../../_metronic/partials';
import { MenuTestPage } from '../pages/MenuTestPage';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import ReleaseNotes from '../pages/release-notes';
import { onMessageListener, requestForToken } from '../utils/firebase';

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ApplicationPage = lazy(() => import('../pages/applications'));
  const JobApplicationsPage = lazy(() => import('../pages/job-applications'));
  const FinancePage = lazy(() => import('../pages/finance'));
  const UsersPage = lazy(() => import('../pages/users'));
  const PaymentsPage = lazy(() => import('../pages/payments'));
  const ReportsPage = lazy(() => import('../pages/reports'));
  const TicketsPage = lazy(() => import('../pages/tickets'));
  const BadgesPage = lazy(() => import('../pages/badges'));
  const IncomeExpensesPage = lazy(() => import('../pages/income-expenses'));
  const ReleaseNotesPage = lazy(() => import('../pages/release-notes'));
  const CategoriesPage = lazy(() => import('../pages/categories'));
  const CorporateCategoriesPage = lazy(() => import('../pages/categories-corporate'));
  const AppUsersPage = lazy(() => import('../pages/app-users'));
  const ActivityCategoriesPage = lazy(() => import('../pages/activity-categories'));
  const ActivitiesPage = lazy(() => import('../pages/activities'));

  requestForToken();
  onMessageListener()
    .then((payload: any) => {
      toast(
        <div style={{ height: '100%', borderLeft: '5px solid green' }}>
          {/* insert your icon here */}
          <span style={{ fontWeight: 'bold', color: '#000' }}>{payload?.notification?.title}</span> {payload?.notification?.body}
        </div>,
      );
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/applications" component={ApplicationPage} />

        <Route path="/site-operations/job" component={JobApplicationsPage} />

        <Route path="/finance/operations" component={FinancePage} />
        <Route path="/finance/income" component={IncomeExpensesPage} />
        <Route path="/reports" component={ReportsPage} />
        <Route path="/users" component={UsersPage} />

        <Route path="/profile/payments" component={PaymentsPage} />
        <Route path="/account" component={AccountPage} />

        <Route path="/tickets" component={TicketsPage} />
        <Route path="/badges" component={BadgesPage} />
        <Route path="/categories" component={CategoriesPage} />
        <Route path="/category/corporate" component={CorporateCategoriesPage} />
        <Route path="/activity/categories" component={ActivityCategoriesPage} />
        <Route path="/activities" component={ActivitiesPage} />
        <Route path="/app-users" component={AppUsersPage} />

        {/* <Route path='/crafted/pages/profile' component={ProfilePage} />  */}
        {/* <Route path="/builder" component={BuilderPageWrapper} /> */}
        {/* <Route path='/crafted/pages/wizards' component={WizardsPage} /> */}
        {/* <Route path='/crafted/widgets' component={WidgetsPage} /> */}
        {/* <Route path="/menu-test" component={MenuTestPage} /> */}
        <Route path="/release-notes" component={ReleaseNotesPage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
