/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useAppSelector } from 'src/services/redux/app/store';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { Logout, AuthPage } from '../modules/auth';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { PrivateRoutes } from './PrivateRoutes';

const Routes: FC = () => {
  const { isAuthorized } = useAppSelector((state) => state.auth, shallowEqual);
  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  );
};

export { Routes };
